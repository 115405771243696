export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILES = 'GET_PROFILES';
export const GET_REPOS = 'GET_REPOS';
export const NO_REPOS = 'NO_REPOS';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const PASSWORD_CHANGE_ERROR = 'PASSWORD_CHANGE_ERROR';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const ACCOUNT_DELETED = 'ACCOUNT_DELETED';
export const GET_SIFS = 'GET_SIFS';
export const GET_SIF = 'GET_SIF';
export const POST_ERROR = 'POST_ERROR';
export const UPDATE_SIF = 'UPDATE_SIF';
export const DELETE_POST = 'DELETE_POST';
export const ADD_SIF = 'ADD_SIF';
export const UPDATE_INTERNAL_DATA = 'UPDATE_INTERNAL_DATA';
export const REMOVE_COMMENT = 'REMOVE_COMMENT';
// new stuff
export const FETCH_EXTERNAL_DATA = 'FETCH_EXTERNAL_DATA';
export const FETCH_API_KEYS = 'FETCH_API_KEYS';
