import React, { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login } from "../../actions/auth";

const Login = ({ login, isAuthenticated, isClient}) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    login(email, password);
  };
  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  } 
  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-md-4 mx-auto ml-2">
          <h1 className="display-4" style={{ fontSize: "2rem" }}>
            Sign In
          </h1>
          <p className="lead">
            <i className="fas fa-user"></i> Sign Into Your Account
          </p>
          <form className="form" onSubmit={onSubmit}>
            <div className="form-group">
              <label htmlFor="email">Email Address</label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Enter your email"
                name="email"
                value={email}
                onChange={onChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                className="form-control mb-3"
                id="password"
                placeholder="Enter your password"
                name="password"
                value={password}
                onChange={onChange}
                minLength="6"
              />
            </div>
            <button
              type="submit"
              className="btn dark-slate-gray-btn"
              style={{
                backgroundColor: "#2c3e50", // Dark slate gray background color
                color: "#fff",
                border: "none",
                borderRadius: "5px",
                padding: "10px 20px",
                cursor: "pointer",
                transition: "background-color 0.3s ease-in-out",
              }}
            >
              Login
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  isClient: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isClient: state.auth.isClient,
});

export default connect(mapStateToProps, { login })(Login);
