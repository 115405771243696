import React, { useState, useEffect } from "react";
import classNames from "classnames"; // Import classNames library for conditional class assignment
import "./TableData.css"; // Import the CSS file

const ActionButtons = ({
  onFetchNew,
  onFetchAccepted,
  onFetchCountered,
  onFetchDenied,
  onFetchDone,
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Attach the event listener when the component mounts
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className={classNames("action-buttons", {
        "MobileButtons": isMobile, // Apply "MobileButtons" class on mobile screens
        "DesktopButtons": !isMobile, // Apply "DesktopButtons" class on desktop screens
      })}
    >
      <button className="btn btn-secondary" onClick={onFetchNew}>
        NEW
      </button>
      <button className="btn btn-secondary" onClick={onFetchAccepted}>
        ACCEPTED
      </button>
      <button className="btn btn-secondary" onClick={onFetchCountered}>
        COUNTERED
      </button>
      <button className="btn btn-secondary" onClick={onFetchDenied}>
        DENIED
      </button>
      <button className="btn btn-secondary" onClick={onFetchDone}>
        DONE
      </button>
    </div>
  );
};

export default ActionButtons;
