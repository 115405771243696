import React, { useState } from "react";
import PropTypes from "prop-types";
import {  useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { changepassword } from "../../actions/auth";


const Settings = ({ changepassword }) => {
  const [newPassword, setNewPassword] = useState("");
  const navigate = useNavigate();
  const handlePasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleSubmit = () => {
    // Prompt the user for confirmation
    const isConfirmed = window.confirm("Are you sure you want to change your password?");
  
    if (isConfirmed) {
      // Call the changepassword function with the new password
      changepassword({ newpassword: newPassword });
  
      // Clear the input field after submission (you can remove this if not needed)
      setNewPassword("");
      navigate("/viewrequests");
    }
  };

  return (
    <div style={{marginTop: "160px"}}>
      <h2 className="mb-4">Change Password</h2>
      <div className="form-group">
        <label htmlFor="newPassword">New Password:</label>
        <input
          type="password"
          className="form-control custom-input"
          id="newPassword"
          name="newPassword"
          value={newPassword}
          onChange={handlePasswordChange}
        />
      </div>
      <div className="btn-container">
        <button className="btn btn-primary" onClick={handleSubmit} style={{padding: "5px 10px", backgroundColor: "#2f4f4f", color: "#fff", border: "none"}}>
          Submit
        </button>
      </div>
    </div>
  );
};

Settings.propTypes = {
  changepassword: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { changepassword })(Settings);
