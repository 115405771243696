import React, { useState, useEffect } from "react";
import classNames from "classnames"; // Import classNames library for conditional class assignment
import "./TableData.css"; // Import the CSS file

const DataGrid = ({ data, onRowClick, selectedRow, onViewClick }) => {
  return (
    <div className="table-responsive">
      <table className="table" table-hover>
        <thead>
          <tr>
            <th>Actions</th> {/* Add a new column for actions */}
            <th>ID</th>
            <th>Client Name</th>
            <th>Agency Name</th>
            <th>Client File Number</th>
            <th>Agency File Number</th>
            <th>Current Balance</th>
            <th>Date of Request</th>
            <th>Requested By</th>
            <th>SIF Amount</th>
            <th>SIF Number of Pays</th>
            <th>Reason</th>
            <th>Status</th>
            <th>Responded By</th>
            <th>Responded Date</th>
            <th>Counteroffer</th>
            <th>Counter Number of Pays</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr
              key={item.id}
              onClick={() => onRowClick(item)} // Handle row click event
              className={selectedRow === item ? "selected-row" : ""}
            >
              <button
                className="btn btn-link"
                onClick={() => onViewClick(item)}
              >
                View
              </button>
              <td>{item.id}</td>
              <td>{item.clientname}</td>
              <td>{item.agencyname}</td>
              <td>{item.clientfilenumber}</td>
              <td>{item.agencyfilenumber}</td>
              <td>{item.currentbalance}</td>
              <td>{item.dateofrequest}</td>
              <td>{item.requestedby}</td>
              <td>{item.sifamount}</td>
              <td>{item.sifnumberofpays}</td>
              <td>{item.reason}</td>
              <td>{item.status}</td>
              <td>{item.respondedby}</td>
              <td>{item.respondeddate}</td>
              <td>{item.counteroffer}</td>
              <td>{item.counternumberofpays}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const TableData = ({ dataList, onViewClick }) => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleRowSelection = (selectedRow) => {
    setSelectedRow(selectedRow);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (isMobile) {
    return (
      <div style={{ marginTop: "220px", marginLeft: "250px" }}>
        <DataGrid
          data={dataList}
          selectedRow={selectedRow}
          onRowClick={handleRowSelection}
          onViewClick={onViewClick}
        />
      </div>
    );
  } else {
    return (
      <div className="DesktopTable">
        <DataGrid
          data={dataList}
          selectedRow={selectedRow}
          onRowClick={handleRowSelection}
          onViewClick={onViewClick}
        />
      </div>
    );
  }
};

export default TableData;
