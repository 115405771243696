import api from "../utils/api";
import {
  PASSWORD_CHANGE_ERROR,
  UPDATE_PASSWORD,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  FETCH_API_KEYS
} from "./types";
import { setAlert } from "./alert";
/*
  NOTE: we don't need a config object for axios as the
 default headers in axios are already Content-Type: application/json
 also axios stringifies and parses JSON for you, so no need for 
 JSON.stringify or JSON.parse
*/

// Load User
export const loadUser = () => async (dispatch) => {
  try {
    const res = await api.get("/users/auth");

    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Login User
export const login = (email, password) => async (dispatch) => {
  const body = { email, password };

  try {
    const res = await api.post("/users/login", body);
    
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });

    dispatch(loadUser());
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      alert(errors.msg);
    }

    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

export const changepassword = (newpassword) => async (dispatch) => {
  try {
    const body = { newpassword };
    const res = await api.put("/users/changepassword", body);
    dispatch({
      type: UPDATE_PASSWORD,
      payload: res.data,
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      alert(errors.msg);
    }
    dispatch({
      type: PASSWORD_CHANGE_ERROR,
    });
  }
};


export const fetch_api_data = () => async(dispatch) => {
  try { 
    const res = await api.get("/users/fetch_api_data");
    dispatch({
      type: FETCH_API_KEYS,
      payload: res.data,
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      alert(errors.msg);
    }
  }
}

// Logout
export const logout = () => ({ type: LOGOUT });
