import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./components/auth/Login";
import { loadUser } from "./actions/auth";
import { Provider } from "react-redux";
import store from "./store";
import PrivateRoute from './components/routing/PrivateRoute';
import Settings from './components/settings/Settings';
import Newrequest from './components/newrequest/Newrequest';
import ViewRequests from './components/viewrequests/ViewRequests';
import Dashboard from './components/dashboard/Dashboard';
import ClientManager from './components/clientmanager/ClientManager';
import { LOGOUT } from './actions/types';
import setAuthToken from './utils/setAuthToken';
import Navbar from './components/layout/Navbar';

import "./App.css";

const App = () => {
  useEffect(() => {
    // check for token in LS when app first runs
    if (localStorage.token) {
      // if there is a token set axios headers for all requests
      setAuthToken(localStorage.token);
    }
    // try to fetch a user, if no token or invalid token we
    // will get a 401 response from our API
    store.dispatch(loadUser());

    // log user out from all tabs if they log out in one tab
    window.addEventListener("storage", () => {
      if (!localStorage.token) store.dispatch({ type: LOGOUT });
    });
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3">
              <Navbar />
            </div>
            <div className="col-md-9"> {/* Adjusted the column width to col-md-9 */}
              <Routes>
                <Route path="" element={<Login />} />
                <Route path="login" element={<Login />} />
                <Route
                  path="dashboard"
                  element={<PrivateRoute component={Dashboard} />}
                />
                <Route
                  path="viewrequests"
                  element={<PrivateRoute component={ViewRequests} />}
                />
                <Route
                  path="clientmanager"
                  element={<PrivateRoute component={ClientManager} />}
                />
                <Route
                  path="newrequest"
                  element={<PrivateRoute component={Newrequest} />}
                />
                <Route
                  path="settings"
                  element={<PrivateRoute component={Settings} />}
                />
              </Routes>
            </div>
          </div>
        </div>
      </Router>
    </Provider>
  );
};

export default App;
