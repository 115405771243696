import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import TableData from "./TableData";
import { getsifs, markasdone } from "../../actions/sif";
import SelectedRowDetails from "./SelectedRowDetails";
import ActionButtons from "./ActionButtons";

import "./ViewRequests.css";

const ViewRequests = ({ getsifs, markasdone, sif: { sifs } }) => {
  useEffect(() => {
    fetchNew();
  }, []); // Initial fetch of "NEW" status data

  const [selectedRowData, setSelectedRowData] = useState({});

  const handleViewClick = (item) => {
    setSelectedRowData(item);
  };

  const onMarkAsDoneClick = (selectedRowData) => {
    markasdone(selectedRowData.id);
    window.location.reload();
  };

  // Function to fetch data with "NEW" status
  const fetchNew = () => {
    getsifs("NEW");
  };

  // Function to fetch data with "ACCEPTED" status
  const fetchAccepted = () => {
    getsifs("ACCEPTED");
  };

  // Function to fetch data with "COUNTERED" status
  const fetchCountered = () => {
    getsifs("COUNTERED");
  };

  // Function to fetch data with "DENIED" status
  const fetchDenied = () => {
    getsifs("DENIED");
  };

  // Function to fetch data with "DONE" status
  const fetchDone = () => {
    getsifs("DONE");
  };

  return (
    <section className="ViewRequests custom-view-requests">
      <div>
        <TableData dataList={sifs} onViewClick={handleViewClick} />
      </div>
      <ActionButtons
        className="action-buttons"
        onFetchNew={fetchNew}
        onFetchAccepted={fetchAccepted}
        onFetchCountered={fetchCountered}
        onFetchDenied={fetchDenied}
        onFetchDone={fetchDone}
      />
      <SelectedRowDetails
        selectedRowData={selectedRowData}
        onMarkAsDoneClick={onMarkAsDoneClick}
      />
    </section>
  );
};

ViewRequests.propTypes = {
  getsifs: PropTypes.func.isRequired,
  markasdone: PropTypes.func.isRequired,
  sif: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sif: state.sif,
});

export default connect(mapStateToProps, { getsifs, markasdone })(ViewRequests);
