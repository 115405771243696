import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addsif } from "../../actions/sif";

const Newrequest = ({ auth: { user }, addsif }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    agencyfilenumber: "",
    clientfilenumber: "",
    currentbalance: "0.00",
    sifamount: "0.00",
    sifnumberofpays: 0,
    reason: "",
    duedate: new Date().toISOString().split("T")[0],
  });

  const {
    agencyfilenumber,
    clientfilenumber,
    currentbalance,
    sifamount,
    sifnumberofpays,
    reason,
    duedate,
  } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    let body = { ...formData };
    addsif(body);
    navigate("/viewrequests");
  };

  

  return (
    <div className="container-fluid">
      <div className="row" style={{ marginTop: "60px" }}>
        <div className="col-md-6">
          <h1>
            Selected Client:{" "}
            <span id="newuserclientname">{user.clientName}</span>
          </h1>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <form onSubmit={onSubmit}>
            <div className="form-group">
              <label htmlFor="agencyfilenumber">Agency Filenumber</label>
              <input
                type="text"
                id="agencyfilenumber"
                name="agencyfilenumber"
                value={agencyfilenumber}
                onChange={onChange}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label htmlFor="clientfilenumber">Client ID</label>
              <input
                type="text"
                id="clientfilenumber"
                name="clientfilenumber"
                value={clientfilenumber}
                onChange={onChange}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label htmlFor="currentbalance">Current Balance</label>
              <input
                type="number"
                id="currentbalance"
                name="currentbalance"
                value={currentbalance}
                onChange={onChange}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label htmlFor="sifamount">Settlement Requested</label>
              <input
                type="number"
                id="sifamount"
                name="sifamount"
                value={sifamount}
                onChange={onChange}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label htmlFor="sifnumberofpays">Number of Pays</label>
              <input
                type="number"
                id="sifnumberofpays"
                name="sifnumberofpays"
                value={sifnumberofpays}
                onChange={onChange}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label htmlFor="datedue">Date Due:</label>
              <input
                type="date"
                id="duedate"
                name="duedate"
                value={duedate}
                onChange={onChange}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label htmlFor="reason">Reason</label>
              <textarea
                id="reason"
                name="reason"
                rows="4"
                cols="50"
                value={reason}
                onChange={onChange}
                className="form-control"
              ></textarea>
            </div>
            <button type="submit" className="btn dark-slate-gray-btn">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

Newrequest.propTypes = {
  auth: PropTypes.object.isRequired,
  addsif: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { addsif })(Newrequest);
