import { GET_SIFS, ADD_SIF, GET_SIF, UPDATE_SIF,FETCH_EXTERNAL_DATA,UPDATE_INTERNAL_DATA } from "../actions/types";

const initialState = {
  sif: "",
  loading: true,
  sifs: [],
  clientdbasedata: null
};

function sifReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_INTERNAL_DATA:
      return {
        ...state,
        ...payload,
      };
    case FETCH_EXTERNAL_DATA:
      return {
        ...state,
        clientdbasedata: payload,
      };
    case GET_SIFS:
      return {
        ...state,
        sifs: payload,
      };
    case GET_SIF:
      return {
        ...state,
        sif: payload,
      };
    case UPDATE_SIF:
      return {
        ...state,
        sif: payload,
      };
    case ADD_SIF:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}

export default sifReducer;
