import React, { useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const Dashboard = ({ auth }) => {
  if (auth.isAuthenticated && !auth.user.isClient) {
    return <Navigate to="/viewrequests" />;
  }
  if (auth.isAuthenticated && auth.user.isClient) {
    return <Navigate to="/clientmanager" />;
  }
};

Dashboard.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Dashboard);
