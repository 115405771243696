import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logout } from "../../actions/auth";
import "./Navbar.css";

const Navbar = ({ auth: { isAuthenticated, user, loading }, logout }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Update windowWidth when the window is resized
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const authLinksDesktopAgent = (
    <nav className="navbar-desktop" style={{ backgroundColor: 'darkslategray', height: '100vh' }}>
      <ul className="navbar-nav">
        <li className="nav-item">
          <a className="nav-link" href="/newrequest">
            New Request
          </a>
        </li>
  
        <li className="nav-item">
          <a className="nav-link" href="/viewrequests">
            View Requests
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="/settings">
            Settings
          </a>
        </li>
        <li className="nav-item">
          <a onClick={logout} href="#!" className="nav-link">
            Logout
          </a>
        </li>
      </ul>
    </nav>
  );
  

  const authLinksDesktopClient = (
    <ul className="navbar-nav ms-auto navbar-desktop">
      <li className="nav-item">
        <a className="nav-link" href="/clientmanager">
          View Requests
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="/settings">
          Settings
        </a>
      </li>
      <li className="nav-item">
        <a onClick={logout} href="#!" className="nav-link">
          Logout
        </a>
      </li>
    </ul>
  );

  const authLinksMobileClient = (
    <nav className="navbar navbar-expand-lg navbar-dark">
      <div className="container-fluid">
        <a className="navbar-brand" href="#">
          {/* Your brand logo or text */}
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
          style={{ border: 'none' }} // Remove border from toggler
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <a className="nav-link" href="/clientmanager">
                View Requests
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/settings">
                Settings
              </a>
            </li>
            <li className="nav-item">
              <a onClick={logout} href="#!" className="nav-link">
                Logout
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
  

  const authLinksMobileAgent = (
    <nav className="navbar navbar-expand-lg navbar-dark" style={{ backgroundColor: 'darkslategray' }}>
      <div className="container-fluid">
        <a className="navbar-brand" href="#"></a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
          style={{ border: 'none' }} // Remove border from toggler
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <a className="nav-link" href="/newrequest">
                New Request
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/viewrequests">
                View Requests
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/settings">
                Settings
              </a>
            </li>
            <li className="nav-item">
              <a onClick={logout} href="#!" className="nav-link">
                Logout
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
  

  const renderNavbar = () => {
    if (windowWidth >= 768) {
      if (isAuthenticated && !user?.isClient && !loading) {
        return <nav>{authLinksDesktopAgent}</nav>;
      } else if (isAuthenticated && user?.isClient) {
        return <nav>{authLinksDesktopClient}</nav>;
      } else {
        return guestLinks;
      }
    } else {
      if (isAuthenticated && !user?.isClient && !loading) {
        return (
          <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
            {authLinksMobileAgent}
          </nav>
        );
      } else if (isAuthenticated && user?.isClient && !loading) {
        // Missing return here for authLinksMobileClient
        return (
          <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
            {authLinksMobileClient}
          </nav>
        );
      } else {
        return guestLinks;
      }
    }
  };

  const guestLinks = null;

  return (
    <div className="position-fixed" style={{ left: 0, top: 0, bottom: 0 }}>
      {/* Vertical navbar goes here */}
      {renderNavbar()}
    </div>
  );
};

Navbar.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(Navbar);
