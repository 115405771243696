import React, { useState, useEffect } from "react";
import "./SelectedRowDetails.css";

const SelectedRowDetails = ({ selectedRowData, onMarkAsDoneClick }) => {
  const [isMobile, setIsMobile] = useState(false); // Initialize with a default value

  // Function to set isMobile based on window width
  const checkIsMobile = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  // Effect to update isMobile when the component mounts and on window resize
  useEffect(() => {
    checkIsMobile(); // Call it initially
    window.addEventListener("resize", checkIsMobile);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []); // Call the effect unconditionally

  // Check if selectedRowData is empty and return null if it is
  if (Object.keys(selectedRowData).length === 0) {
    return null;
  } else {
    console.log(JSON.stringify(selectedRowData));
  }

  if (isMobile) {
    return (
      <div className="container mt-4" style={{ marginLeft: "300px" }}>
        <div className="row">
          <div className="col-12">
            <h4>ID: {selectedRowData.id}</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <h4><strong>Client Name:</strong> {selectedRowData.clientname}</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p><strong>Agency Name:</strong> {selectedRowData.agencyname}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p><strong>Client File Number:</strong> {selectedRowData.clientfilenumber}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p><strong>Agency File Number:</strong> {selectedRowData.agencyfilenumber}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p><strong>Current Balance:</strong> ${selectedRowData.currentbalance}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p><strong>Date of Request:</strong> {selectedRowData.dateofrequest}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p><strong>Requested By:</strong> {selectedRowData.requestedby}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p><strong>SIF Amount:</strong> ${selectedRowData.sifamount}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p><strong>SIF Number of Pays:</strong> {selectedRowData.sifnumberofpays}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p><strong>Reason:</strong> {selectedRowData.reason}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p><strong>Status:</strong> {selectedRowData.status}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p><strong>Responded By:</strong> {selectedRowData.respondedby || "N/A"}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p><strong>Responded Date:</strong> {selectedRowData.respondeddate || "N/A"}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p><strong>Due Date:</strong> {selectedRowData.duedate || "N/A"}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p><strong>Counter Offer:</strong> {selectedRowData.counteroffer || "N/A"}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center">
            {selectedRowData.status === "NEW" && (
              <button
                className="btn btn-primary"
                onClick={() => onMarkAsDoneClick(selectedRowData)}
              >
                Mark As Done
              </button>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="container mt-4">
        <div className="row">
          <div className="col-md-6">
            <h4>ID: {selectedRowData.id}</h4>
          </div>
          <div className="col-md-6">
            <h4>Client Name: {selectedRowData.clientname}</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <p><strong>Agency Name:</strong> {selectedRowData.agencyname}</p>
          </div>
          <div className="col-md-6">
            <p><strong>Client File Number:</strong> {selectedRowData.clientfilenumber}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <p><strong>Agency File Number:</strong> {selectedRowData.agencyfilenumber}</p>
          </div>
          <div className="col-md-6">
            <p><strong>Current Balance:</strong> ${selectedRowData.currentbalance}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <p><strong>Date of Request:</strong> {selectedRowData.dateofrequest}</p>
          </div>
          <div className="col-md-6">
            <p><strong>Requested By:</strong> {selectedRowData.requestedby}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <p><strong>SIF Amount:</strong> ${selectedRowData.sifamount}</p>
          </div>
          <div className="col-md-6">
            <p><strong>SIF Number of Pays:</strong> {selectedRowData.sifnumberofpays}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <p><strong>Reason:</strong> {selectedRowData.reason}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <p><strong>Responded By:</strong> {selectedRowData.respondedby || "N/A"}</p>
          </div>
          <div className="col-md-6">
            <p><strong>Responded Date:</strong> {selectedRowData.respondeddate || "N/A"}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <p><strong>Due Date:</strong> {selectedRowData.duedate || "N/A"}</p>
          </div>
          <div className="col-md-6">
            <p><strong>Counter Offer:</strong> {selectedRowData.counteroffer || "N/A"}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <p><strong>Status:</strong> {selectedRowData.status}</p>
          </div>
          <div className="col-md-12 text-right">
            {selectedRowData.status === "NEW" && (
              <button
                className="btn btn-primary"
                onClick={() => onMarkAsDoneClick(selectedRowData)}
              >
                Mark As Done
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
};

export default SelectedRowDetails;
