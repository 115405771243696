import api from "../utils/api";
import {
  ADD_SIF,
  GET_SIFS,
  UPDATE_SIF,
  FETCH_EXTERNAL_DATA,
  UPDATE_INTERNAL_DATA,
} from "./types";
import axios from "axios";

export const addsif = (body) => async (dispatch) => {
  try {
    const res = await api.post("/sifs/create", body);
    dispatch({
      type: ADD_SIF,
      payload: res.data,
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      alert(errors.msg);
    }
  }
};

export const markasdone = (id) => async (dispatch) => {
  try {
    const res = await api.put(`/sifs/markasdone/${id}`);
    dispatch({
      type: UPDATE_SIF,
      payload: res.data,
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      alert(errors.msg);
    }
  }
};

// Get posts
export const getsifs = (statusName) => async (dispatch) => {
  try {
    // Modify the API call to include the statusName parameter
    const res = await api.get(`/sifs/fetchall/${statusName}`);
    dispatch({
      type: GET_SIFS,
      payload: res.data,
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      alert(errors.msg);
    }
  }
};

export const loginToApi = async (url, data) => {
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      // If the response status is not 2xx, throw an error
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json(); // Parse the JSON response
    return result; // Return the parsed JSON data
  } catch (err) {
    // Fetch errors do not have a `response` object, this block catches network issues and unexpected errors
    console.error("Error during the POST request:", err);

    // You can handle specific fetch errors here
    if (err.message.includes("Failed to fetch")) {
      alert("Network error: Unable to reach the server.");
    } else {
      alert("An error occurred while processing the request.");
    }

    return null;
  }
};

export const updateinternaldata =
  (id, status, countered, counternumberofpays, counteroffer, duedate) =>
  async (dispatch) => {
    try {
      // Set up the PUT request to update the internal data
      const res = await api.put(`/sifs/updateinternal`, {
        id,
        status,
        counternumberofpays,
        counteroffer,
        duedate,
        countered,
      });

      // Dispatch the action with the response data
      dispatch({
        type: UPDATE_INTERNAL_DATA,
        payload: res.data,
      });
    } catch (err) {
      const errors = err.response?.data?.errors || err.message;
      if (errors) {
        alert(errors); // Alert the error message
      } else {
        console.error("Error during the update request:", err);
      }
    }
  };

export const getexternalaccountinfo =
  (filenumber, apikey, apipin) => async (dispatch) => {
    try {
      const loginURI = "https://api.smaxcollectionsoftware.com:8000/login";

      // Data for the login request
      const data = {
        apikey: apikey,
        pin: apipin,
      };
      
      // Login to API and get access token
      const keyData = await loginToApi(loginURI, data);
      
      if (!keyData || !keyData.result || !keyData.result.access_token) {
        // If no access token is received, stop further execution
        alert("Login failed: No access token received");
        return;
      }

      // Extract the access token from keyData.result.access_token
      const accessToken = keyData.result.access_token;

      // Make the GET request with the access token in the headers
      const res = await axios.get(
        `https://api.smaxcollectionsoftware.com:8000/get_sif_dashboard_data/${filenumber}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Attach the token here
          },
        }
      );
      console.log(res);

      // Check if the response is an array and contains an error object
      if (Array.isArray(res.data.result) && res.data.result[0]?.error) {
        throw new Error(res.data.result[0].error); // Throw an error if the array contains an error object
      }

      // Check for errors in the response data outside of an array (for non-array responses)
      if (res.data && res.data.error) {
        throw new Error(res.data.error); // Explicitly throw an error if there's an error in the response
      }

      dispatch({
        type: FETCH_EXTERNAL_DATA,
        payload: res.data.result,
      });
    } catch (err) {
      if (err.response?.data?.error) {
        // Handle specific server errors returned in the response
        alert(`Server Error: ${err.response.data.error}`);
      } else if (err.message) {
        // Handle other custom thrown errors (like the one thrown above)
        alert(`Error: ${err.message}`);
      } else {
        // Handle unexpected errors
        console.error("Error during request:", err);
      }
    }
  };
